.u-list {
    list-style: none;
    padding: 0;
    margin: 24px 0;

    &--common {
        list-style: disc;
    }
}

.u-list__item {
    position: relative;
    font-size: 14px;
    line-height: 20px;

    .u-list--simple & {
        padding-left: 24px;
        margin-bottom: 6px;
    }

    .u-list--tick & {
        padding-left: 30px;
        margin-bottom: 12px;
    }

    .u-list--tick &::before,
    .u-list--simple &::before {
        display: block;
        position: absolute;
        content: '';
    }

    .u-list--simple &::before {
        left: 6px;
        top: 8px;
        width: 6px;
        height: 6px;
        background-color: var(--pink);
    }

    .u-list--tick &::before {
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        background-image: url(./img/icons/icon--tick.svg);
        background-repeat: no-repeat;
        background-size: 16px 16px;
    }
}
