.u-alert {
    position: relative;
    clear: fix;
    z-index: 1;
    min-height: 32px;
    margin-bottom: 24px;
    padding: 24px 6px 24px 64px;

    & :first-child {
        margin-top: 0;
    }

 
    &:not(.u-alert--full, .u-alert--minimal) {
        border-width: 2px;
        border-style: solid;
        border-radius: 8px;
    }

    &--minimal {
        background-color: transparent!important;
        border-color: transparent!important;
        font-weight: 400;
    }

    &--full {
        border-top-width: 2px;
        border-top-style: solid;
        border-bottom-width: 2px;
        border-bottom-style: solid;
    }

    &--toast {
        box-shadow: 0 1px 8px 0 rgba(0,0,0,.12),
        0 2px 4px 0 rgba(0,0,0,.2);
    }

    &::before {
        display: block;
        position: absolute;
        top: 22px;
        left: 33px;
        width: 24px;
        height: 24px;
        content: '';
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: contain;
    }

    &.is_hidden {
        height: 0;
        overflow: hidden;
        padding: 0;
        opacity: 0;
        visibility: hidden;
    }

    /* stylelint-disable */
    ul,
    ol {
        margin-top: 0;
    }

    ul {
        margin-bottom: 14px;
    }

    p a:hover,
    p a:focus,
    li a:hover,
    li a:focus {
        text-decoration: none;
    }
    /* stylelint-enable */
}

.u-alert--half-width{
    width: 50%;
}

.u-alert.u-alert--default,
.u-alert.u-alert--info {
    background-color: #edf3f5;
    border-color: var(--blue-dark);
    
    
    &::before {
        background-image: url(./img/icons/icon--info.svg);
    }

    /* stylelint-disable */
    ul > .u-list__item::before {
        background-color: var(--info-dark);
    }

    p a,
    .u-list__item a {
        color: var(--info-dark);
    }

    .u-keyboard-use p a:focus,
    .u-keyboard-use .u-list__item a:focus {
        outline: 1px solid var(--info-dark);
    }
    /* stylelint-enable */

    &.u-alert--pricing {
        margin: 16px;
    }
}

.u-alert--success {
    &::before {
        background-image: url(./img/icons/icon--success.svg);
    }

    /* stylelint-disable */
    ul > .u-list__item::before {
        background-color: var(--success-dark);
    }

    p a,
    .u-list__item a {
        color: var(--success-dark);
    }

    .u-keyboard-use p a:focus,
    .u-keyboard-use .u-list__item a:focus {
        outline: 1px solid var(--success-dark);
    }
    /* stylelint-enable */
}

.u-alert--warning {
    background-color: var(--warning);
    border-color: var(--warning-dark);

    &::before {
        background-image: url(./img/icons/icon--warning.svg);
    }

    /* stylelint-disable */
    ul > .u-list__item::before {
        background-color: var(--warning-dark);
    }

    p a,
    .u-list__item a {
        color: var(--warning-dark);
    }

    .u-keyboard-use p a:focus,
    .u-keyboard-use .u-list__item a:focus {
        outline: 1px solid var(--warning-dark);
    }
    /* stylelint-enable */
}

.u-alert--error {
    background-color: var(--error);
    border-color: var(--error-dark);

    &::before {
        background-image: url(./img/icons/icon--error.svg);
    }

    /* stylelint-disable */
    ul > .u-list__item::before {
        background-color: var(--error-dark);
    }

    p a,
    .u-list__item a {
        color: var(--error-dark);
    }

    .u-keyboard-use p a:focus,
    .u-keyboard-use .u-list__item a:focus {
        outline: 1px solid var(--error-dark);
    }
    /* stylelint-enable */
}

/* stylelint-disable */
.u-alert__title,
.pn-mc-style__main .u-alert__title,
.u-alert__text,
.u-alert__link,
.u-alert p,
.u-alert ul li {
    color: var(--grey-dark);
    line-height: 20px;
    font-size: 14px;
}
/* stylelint-enable */

.u-alert__link {
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    appearance: none;
    text-decoration: underline;
}

.u-alert__link:hover,
.u-alert__link:focus {
    text-decoration: none;
}

.u-alert__title:not(:last-child),
.u-alert__text:not(:last-child) {
    margin-bottom: 12px;
}

.u-alert__title:not(.u-alert--minimal) {
    font-weight: 400;
}

.u-alert__title,
.pn-mc-style__main .u-alert__title {
    font-family: var(--body);
    font-weight: bold;
}

.u-alert__time {
    display: block;
    font-family: var(--title);
    font-weight: bold;
    text-transform: uppercase;

    @media (--until-tablet) {
        margin-bottom: 10px;
        font-size: 18px;
    }

    @media (--from-tablet) {
        margin-bottom: 15px;
        font-size: 22px;
    }
}

.u-alert--price-increase {
    max-width: 580px;
    margin: auto;
}

.u-alert--price-increase-left {
    max-width: 580px;
    margin-left: 0;
    margin-top: auto;
    margin-bottom: auto;
}
