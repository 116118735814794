.u-button {
    font-family: var(--title);
    border-radius: 4px;
    border: 0;
    line-height: 1;
    cursor: pointer;

    &[disabled],
    &.is_disabled,
    &[disabled]:hover,
    &.is_disabled:hover,
    &[disabled]:focus,
    &.is_disabled:focus {
        cursor: not-allowed;
    }

    &:focus {
        outline: 0;
    }
}

.u-button--primary,
.u-button--secondary {
    font-weight: bold;
    text-decoration: none;
    box-sizing: border-box;
    transition:
        .25s box-shadow var(--bezier),
        .25s border var(--bezier),
        .25s background-color var(--bezier),
        .25s color var(--bezier);

    &:hover {
        box-shadow:
            0 0 8px 0 var(--shadow),
            0 8px 8px 0 var(--shadow-light);
    }

    &:focus {
        box-shadow:
            0 0 0 1px #fff,
            0 0 0 3px var(--shadow);
    }

    @media (--from-tablet) {
        font-size: 24px;
    }

    @media (--until-tablet) {
        font-size: 20px;
    }
}

.u-button--cta.u-button--primary {
    background-color: var(--pink);

    &:hover {
        background-color: var(--pink-lighter);
        color: #fff;
    }

    &:active {
        background-color: var(--pink-darker);
    }
}


.u-button--explore.u-button--primary {
    background-color: var(--explore);

    &:hover {
        background-color: var(--explore-lighter);
    }

    &:active {
        background-color: var(--explore-darker);
    }
}

.u-button--nav.u-button--primary {
    background-color: var(--black-70);

    &:hover {
        background-color: var(--black-60);
    }

    &:active {
        background-color: var(--black-80);
    }
}

.u-button--primary {
    color: #fff;

    &[disabled],
    &.is_disabled,
    &[disabled]:hover,
    &.is_disabled:hover,
    &[disabled]:focus,
    &.is_disabled:focus {
        background-color: var(--black-16);
        color: var(--black-38);
        box-shadow: none;
    }

    @media (--from-tablet) {
        padding: 12px 30px;
    }

    @media (--until-tablet) {
        padding: 6px 16px;
    }
}

.u-button--cta.u-button--primary.u-button--alt {
    color: var(--pink);

    &:hover {
        color: var(--pink-lighter);
    }

    &:active {
        color: var(--pink-darker);
    }
}

.u-button--explore.u-button--primary.u-button--alt {
    color: var(--explore);

    &:hover {
        color: var(--explore-lighter);
    }

    &:active {
        color: var(--explore-darker);
    }
}

.u-button--nav.u-button--primary.u-button--alt {
    color: var(--black-70);

    &:hover {
        color: var(--black-60);
    }

    &:active {
        color: var(--black-80);
    }
}

.u-button--primary.u-button--alt {
    background-color: #fff;

    &:hover {
        background-color: #e5e5e5;
    }

    &:focus {
        box-shadow: 0 0 0 3px var(--white-38);
    }

    &:active {
        background-color: #ccc;
    }

    &[disabled],
    &.is_disabled,
    &[disabled]:hover,
    &.is_disabled:hover,
    &[disabled]:focus,
    &.is_disabled:focus {
        background-color: var(--white-16);
        color: var(--white-38);
        border-color: var(--black-16);
        box-shadow: none;
    }
}

.u-button--primary.u-button--start,
.u-button--primary.u-button--back,
.u-button--secondary.u-button--start,
.u-button--secondary.u-button--back {
    @media (--from-tablet) {
        padding-left: 36px;
        padding-right: 36px;
    }

    @media (--until-tablet) {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.u-button--primary.u-button--start,
.u-button--secondary.u-button--start {
    &::after {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-top-width: 2px;
        border-right-width: 2px;
        border-top-style: solid;
        border-right-style: solid;
        transform-origin: 50% 50%;
        margin-left: 12px;
        transform: rotate(45deg) translate(-3px, 0);
        content: '';
    }
}

.u-button--primary.u-button--back,
.u-button--secondary.u-button--back {
    &::before {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-top-width: 2px;
        border-right-width: 2px;
        border-top-style: solid;
        border-right-style: solid;
        transform-origin: 50% 50%;
        transform: rotate(-135deg) translate(0, 3px);
        margin-right: 12px;
        content: '';
    }
}

.u-button--primary.u-button--start::before,
.u-button--primary.u-button--start::after {
    border-top-color: #fff;
    border-right-color: #fff;
}

.u-button--primary.u-button--start[disabled]::after,
.u-button--primary.u-button--start.is_disabled::after,
.u-button--primary.u-button--start[disabled]:hover::after,
.u-button--primary.u-button--start.is_disabled:hover::after,
.u-button--primary.u-button--start[disabled]:focus::after,
.u-button--primary.u-button--start.is_disabled:focus::after,
.u-button--primary.u-button--back[disabled]::before,
.u-button--primary.u-button--back.is_disabled::before,
.u-button--primary.u-button--back[disabled]:hover::before,
.u-button--primary.u-button--back.is_disabled:hover::before,
.u-button--primary.u-button--back[disabled]:focus::before,
.u-button--primary.u-button--back.is_disabled:focus::before {
    border-top-color: var(--black-38);
    border-right-color: var(--black-38);
}

.u-button--cta.u-button--alt.u-button--start::after,
.u-button--cta.u-button--alt.u-button--back::before {
    border-top-color: var(--pink);
    border-right-color: var(--pink);
}

.u-button--cta.u-button--alt.u-button--start:hover::after,
.u-button--cta.u-button--alt.u-button--back:hover::before {
    border-top-color: var(--pink-lighter);
    border-right-color: var(--pink-lighter);
}

.u-button--cta.u-button--alt.u-button--start:active::after,
.u-button--cta.u-button--alt.u-button--back:active::before {
    border-top-color: var(--pink-darker);
    border-right-color: var(--pink-darker);
}

.u-button--explore.u-button--alt.u-button--start::after,
.u-button--explore.u-button--alt.u-button--back::before {
    border-top-color: var(--explore);
    border-right-color: var(--explore);
}

.u-button--explore.u-button--alt.u-button--start:hover::after,
.u-button--explore.u-button--alt.u-button--back:hover::before {
    border-top-color: var(--explore-lighter);
    border-right-color: var(--explore-lighter);
}

.u-button--explore.u-button--alt.u-button--start:active::after,
.u-button--explore.u-button--alt.u-button--back:active::before {
    border-top-color: var(--explore-darker);
    border-right-color: var(--explore-darker);
}

.u-button--cta.u-button--secondary {
    border: 1px solid var(--pink);
    color: var(--pink);

    &:hover {
        border-color: var(--pink-lighter);
    }

    &:active {
        border-color: var(--pink-darker);
        color: var(--pink-darker);
    }
}

.u-button--explore.u-button--secondary {
    border: 1px solid var(--explore);
    color: var(--explore);

    &:hover {
        border-color: var(--explore-lighter);
    }

    &:active {
        border-color: var(--explore-darker);
        color: var(--explore-darker);
    }
}

.u-button--nav.u-button--secondary {
    border: 1px solid var(--black-70);
    background-color: #fff;
    color: var(--black-70);

    &:hover {
        border-color: var(--black-60);
        color: var(--black-60);
    }

    &:active {
        border-color: var(--black-80);
        color: var(--black-80);
    }
}

.u-button--secondary {
    &[disabled],
    &.is_disabled,
    &[disabled]:hover,
    &.is_disabled:hover,
    &[disabled]:focus,
    &.is_disabled:focus {
        background-color: #fff;
        color: var(--black-38);
        border-color: var(--black-16);
        box-shadow: none;
    }

    @media (--from-tablet) {
        padding: 9px 30px;
    }

    @media (--until-tablet) {
        padding: 5px 16px;
    }
}

.u-button--cta.u-button--secondary.u-button--alt {
    &:hover {
        color: var(--pink-lighter);
    }

    &:active {
        color: var(--pink-darker);
    }

    &:focus {
        color: var(--pink);
    }
}

.u-button--explore.u-button--secondary.u-button--alt {
    &:hover {
        color: var(--explore-lighter);
    }

    &:active {
        color: var(--explore-darker);
    }

    &:focus {
        color: var(--explore);
    }
}

.u-button--nav.u-button--secondary.u-button--alt {
    background-color: #fff;
    color: var(--black-70);

    &:hover {
        color: var(--black-60);
        background-color: #fff;
    }

    &:active {
        color: var(--black-80);
    }

    &:focus {
        color: var(--black-70);
    }
}

.u-button--secondary.u-button--alt {
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;

    &:hover {
        border-color: #fff;
        color: var(--pink-lighter);
    }

    &:active {
        border-color: #fff;
        background-color: #e5e5e5;
    }

    &:focus {
        box-shadow: 0 0 0 3px var(--white-38);
    }

    &[disabled],
    &.is_disabled,
    &[disabled]:hover,
    &.is_disabled:hover,
    &[disabled]:focus,
    &.is_disabled:focus {
        background-color: #fff;
        color: var(--black-38);
        border-color: var(--black-16);
        box-shadow: none;
    }
}

.u-button--cta.u-button--tertiary {
    color: var(--pink);
}

.u-button--explore.u-button--tertiary {
    color: var(--explore);
}

.u-button--tertiary {
    background-color: transparent;
    border: 0;
    font-weight: normal;
    font-size: 20px;
    text-decoration: underline;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &[disabled],
    &.is_disabled,
    &[disabled]:hover,
    &.is_disabled:hover,
    &[disabled]:focus,
    &.is_disabled:focus {
        color: var(--black-38);
        text-decoration: none;
    }
}

.u-button--tertiary.u-button--alt {
    color: #fff;

    &[disabled],
    &.is_disabled,
    &[disabled]:hover,
    &.is_disabled:hover,
    &[disabled]:focus,
    &.is_disabled:focus {
        color: var(--white-60);
        text-decoration: none;
    }
}

.u-button--compact {
    @media (--from-tablet) {
        font-size: 20px;
        padding: 6px 16px;

        &.u-button--start,
        &.u-button--back {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    @media (--until-tablet) {
        font-size: 20px;
        padding: 6px 16px;

        &.u-button--start,
        &.u-button--back {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}
