.u-slant {
    position: relative;
    overflow: hidden;

    &--top::before,
    &--bottom::after {
        content: '';
        position: absolute;
        background-color: #fff;
        width: 110vw;
        height: 8.8vw;
        transform: rotate(-5deg);
    }

    &--top {
        padding-top: 8.7vw;

        &::before {
            top: -8.8vw;
            right: 0;
            transform-origin: 100% 100%;
        }
    }

    &--bottom {
        padding-bottom: 8.7vw;

        &::after {
            bottom: -8.8vw;
            left: 0;
            transform-origin: 0 0;
        }
    }
}
