@font-face {
    font-family: 'Plusnet Creighton';
    src:
        url(/bundles/plusnetplusnetassets/fonts/plusnetcreightonprobold-webfont.woff2) format('woff2'),
        url(/bundles/plusnetplusnetassets/fonts/plusnetcreightonprobold-webfont.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Plusnet Creighton';
    src:
        url(/bundles/plusnetplusnetassets/fonts/plusnetcreightonprobook-webfont.woff2) format('woff2'),
        url(/bundles/plusnetplusnetassets/fonts/plusnetcreightonprobook-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}
