.u-form {
    margin-bottom: 36px;
}

.u-form__title,
.u-form__sub-title,
.u-form__content {
    margin-top: 0;
}

.u-form__title {
    @media (--until-desktop) {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 18px;
    }

    @media (--desktop) {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 24px;
    }
}

.u-form__sub-title {
    @media (--until-desktop) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 6px;
    }

    @media (--desktop) {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 12px;
    }
}

.u-form__content {
    &.is_inactive {
        display: none;
    }

    @media (--until-desktop) {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 18px;
    }

    @media (--desktop) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 24px;
    }
}

.u-form__row {
    clear: fix;

    &--inline {
        display: inline-block;
        margin-right: 24px;
        vertical-align: bottom;
    }

    &--inline-short {
        margin-bottom: 0;
    }

    &--conditional {
        display: none;
        background-color: var(--grey-lightest);
        padding: 12px;

        &.is_active {
            display: block;
        }
    }

    @media (--until-desktop) {
        margin-bottom: 18px;

        &:last-child {
            margin-bottom: 14px;
            padding-top: 14px;
        }

        & > .u-form__row--conditional {
            margin-top: 18px;
        }
    }

    @media (--desktop) {
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 14px;
            padding-top: 14px;
        }

        & > .u-form__row--conditional {
            margin-top: 24px;
        }

        &--inline:last-child,
        &--inline:nth-last-child(2),
        &--inline:nth-last-child(3) {
            margin-bottom: 0;
        }
    }
}
.u-form__fieldset {
    position: relative;
    border: 1px solid var(--grey-light);

    &.is_inactive {
        display: none;
    }

    :last-child {
        margin-bottom: 0;
    }

    @media (--until-desktop) {
        padding: 48px 12px 12px;
        margin-bottom: 18px;

        &--no-legend {
            padding-top: 12px;
        }
    }

    @media (--desktop) {
        padding: 78px 24px 24px;
        margin-bottom: 24px;

        &--no-legend {
            padding-top: 24px;
        }
    }
}

.u-form__legend {
    position: absolute;
    padding: 0;
    margin: 0;
    font-family: var(--title);
    font-weight: bold;

    @media (--until-desktop) {
        top: 12px;
        left: 12px;
        font-size: 18px;
        line-height: 24px;
    }

    @media (--desktop) {
        top: 24px;
        left: 24px;
        font-size: 24px;
        line-height: 30px;
    }
}

.u-form__label {
    margin-bottom: 6px;
    display: block;

    @media (--until-desktop) {
        font-size: 14px;
    }

    @media (--desktop) {
        font-size: 16px;
    }
}

.u-form__checkbox-contain,
.u-form__radio-contain,
.u-form__select-contain {
    margin-bottom: 0;

    & + .u-form__additional-information {
        margin-top: 12px;
    }
}

.u-form__checkbox-label,
.u-form__checkbox-contain,
.u-form__checkbox-input,
.u-form__checkbox-label::before,
.u-form__checkbox-label::after,
.u-form__radio-label,
.u-form__radio-contain,
.u-form__radio-input,
.u-form__radio-label::before,
.u-form__radio-label::after,
.u-form__select-contain,
.u-form__select-input,
.u-form__input,
.u-form__textarea,
.u-form__submit {
    font-family: var(--body);
    line-height: 20px;
    font-size: 14px;
}

.u-form__checkbox-label::before,
.u-form__checkbox-label::after,
.u-form__checkbox-input,
.u-form__radio-label::before,
.u-form__radio-label::after,
.u-form__radio-input {
    width: 32px;
    height: 32px;
}

.u-form__checkbox-label,
.u-form__checkbox-contain,
.u-form__radio-label,
.u-form__radio-contain {
    position: relative;
}

.u-form__checkbox-label {
    position: relative;
    display: flex;
    cursor: pointer;
    padding-top: 14px;
    padding-bottom: 14px;
    align-items: center;

    &::before,
    &::after {
        display: block;
        position: absolute;
        left: 0;
        height: 32px;
        width: 32px;
        content: '';
        pointer-events: none;
        box-sizing: border-box;
        border-radius: 4px;
    }

    &::before {
        background-color: #fff;
        border: 2px solid var(--black-38);
        transition: .25s border-color ease;
    }

    &::after {
        background-color: var(--blue-dark);
        background-image: url(./img/icons/icon--functional-tick.svg);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 18px auto;
        transition: .25s opacity ease;
        opacity: 0;
    }

    &:hover::before,
    &:focus::before {
        border-color: var(--black-60);
    }

    @media (--until-desktop) {
        padding-left: 42px;
        max-height: 48px;
    }

    @media (--desktop) {
        padding-left: 48px;
    }
}

.u-form__checkbox-supplementary {
    margin-top: 0;
    padding-left: 42px;
}

.u-form__radio-label {
    position: relative;
    display: block;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 42px;
    cursor: pointer;

    &::before,
    &::after {
        display: block;
        position: absolute;
        content: '';
        pointer-events: none;
        box-sizing: border-box;
        border-radius: 50%;
    }

    &::before {
        top: 8px;
        left: 0;
        width: 32px;
        background-color: #fff;
        border: 2px solid var(--black-38);
        transition: .25s border-color ease;
    }

    &::after {
        top: 16px;
        left: 8px;
        width: 16px;
        height: 16px;
        transition: .25s opacity ease;
        opacity: 0;
        background-color: var(--blue-dark);
    }

    &:hover::before,
    &:focus::before {
        border-color: var(--black-60);
    }

    &:hover::after,
    &:focus::after {
        opacity: 0;
    }
}

.u-form__radio-input,
.u-form__checkbox-input {
    position: absolute;
    top: 8px;
    left: 0;
    width: 32px;
    opacity: 0;
    appearance: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    cursor: pointer;
}

.u-form__radio-input:focus + .u-form__radio-label::before,
.u-form__checkbox-input:focus + .u-form__checkbox-label::before {
    border-color: var(--black-60);
    box-shadow: 0 0 0 3px var(--black-16);
}

.u-form__radio-input:focus + .u-form__radio-label::after,
.u-form__checkbox-input:focus + .u-form__checkbox-label::after {
    opacity: 0;
}

/*
 * Show the tick when it's checked/show the radio filled in when checked
 */
.u-form__radio-input:checked + .u-form__radio-label::before,
.u-form__checkbox-input:checked + .u-form__checkbox-label::before {
    background-color: #fff;
    border-color: var(--blue-dark);
}

.u-form__radio-input:checked + .u-form__radio-label::after,
.u-form__checkbox-input:checked + .u-form__checkbox-label::after {
    opacity: 1;
}

/* stylelint-disable */
.u-form__radio-input[disabled] + .u-form__radio-label:hover::after,
.u-form__radio-input[disabled] + .u-form__radio-label:focus::after,
.u-form__radio-input[disabled]:checked + .u-form__radio-label::before,
.u-form__radio-input[disabled]:checked + .u-form__radio-label::after,
.u-form__radio-input[disabled]:checked + .u-form__radio-label:hover::before,
.u-form__radio-input[disabled]:checked + .u-form__radio-label:focus::before {
    background-color: var(--black-5);
    border-color: var(--black-16);
}

.u-form__checkbox-input[disabled] + .u-form__checkbox-label:hover::after,
.u-form__checkbox-input[disabled] + .u-form__checkbox-label:focus::after,
.u-form__checkbox-input[disabled]:checked + .u-form__checkbox-label::before,
.u-form__checkbox-input[disabled] + .u-form__checkbox-label::before,
.u-form__checkbox-input[disabled] + .u-form__radio-label::before,
.u-form__checkbox-input[disabled]:checked + .u-form__checkbox-label:hover::before,
.u-form__checkbox-input[disabled]:checked + .u-form__checkbox-label:focus::before,
.u-form__checkbox-input[disabled]:checked + .u-form__checkbox-label::after {
    background-color: var(--black-16);
    border-color: var(--black-16);
}

.u-form__radio-input[disabled]:checked + .u-form__radio-label::after,
.u-form__radio-input[disabled]:checked + .u-form__radio-label:hover::after,
.u-form__radio-input[disabled]:checked + .u-form__radio-label:focus::after,
.u-form__checkbox-input[disabled]:checked + .u-form__checkbox-label::after,
.u-form__checkbox-input[disabled]:checked + .u-form__checkbox-label:hover::after,
.u-form__checkbox-input[disabled]:checked + .u-form__checkbox-label:focus::after {
    opacity: .5;
}
/* stylelint-enable */

.u-form__textarea[disabled],
.u-form__textarea[disabled]:hover,
.u-form__textarea[disabled]:focus,
.u-form__input[disabled],
.u-form__input[disabled]:hover,
.u-form__input[disabled]:focus {
    border-color: var(--black-16);
}

.u-form__select-contain {
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid var(--grey);
    transition: .25s border-color ease;

    &::after {
        display: block;
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translate(0, -50%);
        width: 0;
        height: 0;
        border-top: 4px solid var(--grey);
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        content: '';
        pointer-events: none;
    }

    &:hover,
    &:focus {
        border-color: var(--pink);
    }
}

.u-form__checkbox-input:focus,
.u-form__checkbox-label:focus,
.u-form__radio-input:focus,
.u-form__radio-label:focus,
.u-form__select-input:focus,
.u-form__select-label:focus,
.u-form__input:focus,
.u-form__label:focus,
.u-form__textarea:focus {
    outline: 0;
}

.u-form__select-input {
    display: block;
    width: 100%;
    border: 0;
    margin: 0;
    padding: 0 30px 0 12px;
    cursor: pointer;
    appearance: none;
    background-color: transparent;
}

.u-form__input,
.u-form__textarea {
    width: 100%;
    border: 1px solid var(--black-38);
    color: var(--black-70);
    background-color: #fff;
    border-radius: 5px;
    box-sizing: border-box;
    transition:
        .25s border-color ease,
        .25s background-color ease,
        .25s box-shadow ease;

    &:hover {
        border-color: var(--black-70);
    }

    &:focus {
        border-color: var(--info-dark);
        box-shadow: 0 0 0 2px var(--info-dark);

        .u-keyboard-use & {
            box-shadow: 0 0 0 4px var(--black-16);
        }
    }

    &--error {
        border-color: var(--error-dark);
    }

    &--warning {
        border-color: var(--warning-dark);
    }

    &--info {
        border-color: var(--info-dark);
    }

    &--success {
        border-color: var(--error-dark);
    }
}

.u-form__input {
    padding: 0 12px;
    line-height: 48px;
    height: 48px;

    &--sort-code {
        display: inline-block;
        width: 72px;
        text-align: center;
    }
}

.u-form__textarea {
    padding: 12px;

    @media (--until-desktop) {
        height: 120px;
    }

    @media (--desktop) {
        height: 160px;
    }
}

.u-form__additional-information {
    font-size: 14px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 12px;
}

.u-form__required {
    display: inline-block;
    vertical-align: bottom;
    width: 18px;
    height: 22px;
    text-indent: 115%;
    overflow: hidden;
    white-space: nowrap;
    background-image: url(./img/icons/icon--asterisk.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
}

.u-form__errors-list {
    list-style: square;
    margin-top: 0;
    margin-bottom: 24px;
    padding-left: 24px;
}

.u-form__errors-item {
    &.is_hidden {
        display: none;
    }

    @media (--until-desktop) {
        font-size: 14px;
        line-height: 20px;
    }

    @media (--desktop) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 6px;
    }
}
