.u-switch-banner {
    color: var(--black-70);
    font-size: 16px;
    line-height: 24px;
    padding-top: 24px;
}

.u-switch-banner__inner {
    padding-top: 48px;

    @media (--from-tablet) {
        display: flex;
    }
}

.u-switch-banner__text {
    margin-bottom: 24px;
    text-align: center;
    @media (--mobile-large) {
        display: flex;
    }
    @media (--from-tablet) {
        width: 50%;
        text-align: left;
    }
}

.u-switch-banner__text-row {
    @media (--mobile-large) {
        width: 50%;
    }
}

.u-switch-banner__headline {
    margin: 0 0 16px;
    font-family: var(--title);
    text-align: center;
    font-size: 36px;
    line-height: 32px;
    color: var(--black-70);

    @media (--from-tablet) {
        font-size: 43px;
        line-height: 36px;
    }
}

.u-switch-banner__content {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    font-family: var(--body);
    @media (--from-tablet) {
        width: 564px;
    }
}

.u-switch-banner__text-heading {
    font-size: 20px;
    line-height: 20px;
    font-family: var(--title);
    @media (--from-tablet) {
        font-size: 24px;
        line-height: 24px;
    }
}

.u-switch-banner__text-content {
    font-family: var(--body);
    font-size: 16px;
    line-height: 24px;
    margin: 24px 0;
}

.u-switch-banner__icons {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;

    @media (--mobile) {
        width: 100%;
    }

    @media (--mobile-large) {
        width: 100%;
    }

    @media (--from-tablet) {
        flex-direction: column;
        width: 50%;
    }
}

.u-switch-banner__icons-row {
    width: 100%;
    @media (--mobile-large) {
        width: 50%;
        display: inline-block;
    }
    @media (--from-tablet) {
        display: flex;
        justify-content: space-around;
    }
}

.u-switch-banner__icon {
    width: 100%;
    max-width: 200px;
    height: 124px;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
    @media (--mobile-large) {
        width: 50%;
        display: inline-block;
    }
    @media (--from-tablet) {
        max-width: 228px;
    }
}

.u-switch-banner__icon--customer {
    background-image: url('/bundles/plusnetplusnetassets/images/awards/uswitch-awards-customer.png');
}

.u-switch-banner__icon--year {
    background-image: url('/bundles/plusnetplusnetassets/images/awards/uswitch-awards-year.png');
}

.u-switch-banner__icon--value {
    background-image: url('/bundles/plusnetplusnetassets/images/awards/uswitch-awards-value.png');
}

.u-switch-banner__icon--popular {
    background-image: url('/bundles/plusnetplusnetassets/images/awards/uswitch-awards-popular.png');
}
