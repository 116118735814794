.u-legal {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0);
    position: relative;

    &__container {
        background: #fff;
    }

    .u-expander-trigger {
        max-width: none;
        height: 60px;
        margin: 0 auto;
        padding: 0;
        user-select: none;

        &:hover {
            background-color: #dbeaf1;
        }
    }

    /* Reveal more button */
    .u-reveal-more {
        display: block;
        padding-left: 42px;
        position: relative;
        cursor: pointer;
        width: 100%;
        margin: 6px auto 0;
        padding-top: 12px;
        box-sizing: border-box;
    }

    /* non full-width version for use on packages pages*/
    .u-reveal-more-short {
        display: inline-block;
        border-radius: 6px;
        padding: 10px;
    }

    /* Only suggest clickable functionality when js is enabled */
    .u-reveal-more::before,
    .u-reveal-more-ie-after {
        border-radius: 4px;
        display: inline-block;
        height: 18px;
        left: 10px;
        margin-top: -9px;
        width: 23px;
        content: '';
        margin-right: 20px;
    }

    .is_closed .u-reveal-more::before,
    .is_closed .u-reveal-more-ie-after {
        background: #676d70 url(/bundles/plusnetplusnetassets/images/clipped-sprite.png) no-repeat -102px -6px;
    }

    .is_open .u-reveal-more::before,
    .is_open .u-reveal-more-ie-after {
        background: #676d70 url(/bundles/plusnetplusnetassets/images/clipped-sprite.png) no-repeat -103px -55px;
    }

    .is_closed.u-expander-content {
        display: none;
    }

    .u-expander-content {
        font-size: 14px;
    }
}
