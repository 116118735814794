.u-contain {
    position: relative;
    max-width: var(--width);
    width: 100%;
    margin: 0 auto;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
}

.u-contain-general {
    position: relative;
    max-width: var(--width);
    width: 100%;
    margin: 20px auto;
}

.u-contain--fat {
    max-width: 1200px;

    @media (--from-tablet), (--mobile-large) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media (--mobile) {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.u-contain--mid {
    width: 66%;

    @media (--mobile) {
        width: 100%;
    }

    @media (--tablet) {
        width: 80%;
    }
}

.u-contain--slim {
    @media (--mobile) {
        width: 100%;
    }

    @media (--tablet) {
        width: 64%;
    }

    @media (--desktop) {
        width: 54%;
    }

    @media (--desktop-large) {
        width: 60%;
    }
}
