.u-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(#000, .7);
    opacity: 0;
    pointer-events: none;
    transition: .25s opacity ease, .25s z-index ease;

    @media (--mobile) {
        line-height: 18px;
        font-size: 12px;
    }

    @media (--from-tablet) {
        line-height: 20px;
        font-size: 14px;
    }

    .no-js & {
        position: relative;
        top: auto;
        left: auto;
        height: auto;
        z-index: 1;
        opacity: 1;
        pointer-events: auto;
        background-color: #fff;
    }

    &.is_visible {
        opacity: 1;
        z-index: 101;/* To beat the big z-index on global nav */
        pointer-events: auto;

        .u-popup__dialog {
            transform: translate(-50%, -50%);
        }
    }
}

.u-popup__dialog {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    transform: translate(-50%, -50%);
    transition: .25s transform ease;
    box-sizing: border-box;

    .is_visible & {
        display: block;
    }

    :last-child {
        margin-bottom: 0;
    }

    .no-js & {
        position: relative;
        top: auto;
        left: auto;
        max-width: var(--width);
        width: 100%;
        margin: 0 auto 20px;
        padding: 24px 12px;
        border-radius: 0;
        transform: translate(0, 0);
        background-color: var(--grey-lightest);
    }

    @media (--desktop) {
        padding: 36px;
        max-width: 768px;
    }

    @media (--tablet) {
        padding: 48px;
        max-width: 768px;
    }

    @media (--mobile-large) {
        max-width: 511px;
        padding: 32px;
    }

    @media (--mobile) {
        max-width: calc(100% - 12px);
        padding: 24px;
    }
}

.u-popup__title {
    margin: 0 0 24px;
    font-family: var(--title);

    @media (--desktop) {
        font-size: 32px;
        line-height: 36px;
    }

    @media (--tablet) {
        padding-right: 54px;
        font-size: 28px;
        line-height: 32px;
    }

    @media (--until-tablet) {
        padding-right: 44px;
        font-size: 24px;
        line-height: 28px;
    }
}

.u-popup__text {
    margin-top: 0;

    @media (--from-tablet) {
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 22px;
    }

    @media (--until-tablet) {
        margin-bottom: 18px;
        font-size: 12px;
        line-height: 18px;
    }
}

.u-popup__list {
    list-style: none;
    padding: 0;
    margin: 24px 0;
}

.u-popup__item {
    position: relative;
    padding-left: 24px;
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 20px;

    &::before {
        display: block;
        position: absolute;
        left: 4px;
        top: 4px;
        width: 4px;
        height: 4px;
        background-color: var(--pink);
        content: '';
    }
}

.u-popup__close {
    &--text {
        background-color: #fff;
        color: var(--grey-dark);
        text-decoration: underline;
    }

    &--icon {
        position: absolute;
        z-index: 2;
        padding: 0;
        border: 0;
        border-radius: 6px;
        background-color: var(--purple);
        text-indent: 115%;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
        transition: .25s background-color ease;

        &::before,
        &::after {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 16px;
            height: 2px;
            content: '';
            background-color: #fff;
        }

        &::before {
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:hover,
        &:focus {
            background-color: var(--grey-dark);
            outline: 0;
        }
    }

    .no-js & {
        display: none;
    }

    @media (--from-tablet) {
        &--icon {
            top: 24px;
            right: 24px;
            width: 44px;
            height: 44px;
        }
    }

    @media (--until-tablet) {
        &--icon {
            top: 12px;
            right: 12px;
            width: 32px;
            height: 32px;
        }
    }
}

.u-popup--loading .u-popup__dialog {
    min-height: 100px;

    &::before {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        border-width: 10px;
        border-style: solid;
        border-color: #fff #fff #fff var(--pink);
        border-radius: 50%;
        content: '';
        transform: rotate(0deg) translate(-50%, -50%);
        animation-name: modalspin;
        animation-duration: .8s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        transform-origin: 0 0;
    }
}

@keyframes modalspin {
    from {
        transform: rotate(0deg) translate(-50%, -50%);
    }

    to {
        transform: rotate(360deg) translate(-50%, -50%);
    }
}
