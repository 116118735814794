.u-card__contain {
    width: 100%;
    margin: 12px 0;
    display: flex;

    &--wrap {
        flex-wrap: wrap;
    }

    &--compare {
        @media (--until-tablet) {
            flex-direction: column;
            align-items: stretch;
            max-width: 360px;
            margin-left: auto;
            margin-right: auto;
        }

        @media (--from-tablet) {
            justify-content: space-around;
            align-items: stretch;
        }
    }

    &--stacked {
        flex-direction: column;
        align-items: flex-start;
    }
}

.u-card__contain--broadband {
    margin: 38px 0;
    row-gap: 38px;

    @media (--desktop) {
        column-gap: 10px;
        row-gap: 46px;
    }

    @media (--tablet) {
        column-gap: 18px;
    }

    @media (--from-tablet) {
        margin: 46px 0;
        align-items: flex-start;
    }
}

.u-card {
    display: flex;
    flex-direction: column;
    margin: 0 0 24px;
    border: 1px solid var(--grey-light);
    border-radius: 8px;
    box-shadow: 1px 1px 10px 1px var(--grey-light);

    .u-card__contain--stacked & {
        width: 100%;
    }

    .u-card__contain--compare & {
        @media (--tablet) {
            flex: 1 1 33%;

            &:not(:last-child) {
                margin-right: 18px;
            }
        }

        @media (--desktop) {
            flex: 1 1 33%;

            &:not(:last-child) {
                margin-right: 24px;
            }
        }
    }
}

.u-card__content {
    :last-child {
        margin-bottom: 0;
    }

    @media (--desktop) {
        padding: 24px;
    }

    @media (--tablet) {
        padding: 24px 18px;
    }

    @media (--until-tablet) {
        padding: 24px 12px;
    }
}

.u-card__link {
    text-decoration: underline;
    color: var(--pink);
    transition: .25s color ease;

    &:hover,
    &:focus {
        color: var(--blue-dark);
        text-decoration: none;
    }
}

.u-card__title {
    margin: 0 0 18px;
    font-family: var(--title);
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;

    &--center {
        text-align: center;
    }

    &--alt {
        color: #fff;
    }

    &--primary {
        font-weight: normal;
        color: var(--grey-dark);
    }

    &--product {
        color: var(--purple);
    }

    &--icon::before {
        display: block;
        width: 63px;
        height: 63px;
        margin: 0 auto 24px;
        content: '';
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.u-card__description {
    margin: 24px 0;
    padding: 0;
    font-size: 14px;
    line-height: 22px;
}

.u-card__cta-bar {
    display: flex;
    background: var(--grey-lightest);
    border-radius: 0 0 8px 8px;

    &--row {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media (--mobile) {
            flex-direction: column;
            align-items: center;
        }
    }

    &--column {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    @media (--desktop) {
        padding: 24px;
    }

    @media (--tablet) {
        padding: 24px 18px;
    }

    @media (--until-tablet) {
        padding: 24px 12px;
    }
}

.u-card__price {
    .u-card__cta-bar--column & {
        margin-bottom: 12px;
    }

    .u-card__cta-bar--row & {
        @media (--mobile) {
            margin-bottom: 6px;
        }
    }
}

.u-card__price-value {
    display: inline-block;
    margin-right: 4px;
    font-family: var(--title);
    font-weight: bold;
    font-size: 40px;
    line-height: 46px;
    color: var(--dark-grey);

    &--promotion {
        color: var(--pink-bright);
    }
}

.u-card__price-info {
    display: inline-block;
    font-size: 18px;
    line-height: 18px;
    color: var(--grey-dark);
    font-family: var(--title);
}
