:root {
    --pink: hsl(331.8, 73.9%, 45.1%);
    --pink-lighter: #de237b;
    --pink-darker: #b31b63;
    --pink-bright: hsl(324.3, 100%, 46.1%);
    --pink-disco:hsl(319, 100%, 44%);
    --pink-highlighter:#FF49C5;
    --purple: hsl(317.8, 100%, 26.5%);
    --blue-dark: hsl(205, 100%, 32%);
    --blue: hsl(192.2, 100%, 39.6%);
    --blue-light: hsl(195, 28.6%, 94.5%);
    --grey-dark: hsl(210, 4%, 30%);
    --grey: hsl(200, 4%, 42%);
    --grey-light: hsl(180, 3%, 88%);
    --grey-lightest: hsl(195, 29%, 95%);
    --blue-grey: hsl(195, 29%, 97%);

    --explore: hsl(192.1, 100%, 28.2%);
    --explore-lighter: #0090b4;
    --explore-darker: #0080a1;

    --info: hsl(195, 84.6%, 89.8%);
    --info-dark: hsl(205.3, 100%, 31.6%);
    --warning: hsl(43.7, 72.7%, 87.1%);
    --warning-dark: hsl(33.9, 100%, 50%);
    --success: hsl(88.2, 65.4%, 79.6%);
    --success-dark: hsl(80, 100%, 30%);
    --error: hsl(4.6, 86.7%, 94.1%);
    --error-dark: hsl(0, 85.3%, 42.7%);

    --black-5: hsl(0, 0%, 95%);
    --black-16: hsl(0, 0%, 84%);
    --black-38: hsl(0, 0%, 62%);
    --black-60: hsl(0, 0%, 40%);
    --black-70: hsl(0, 0%, 30%);
    --black-80: hsl(0, 0%, 20%);
    --black-100: #000000;

    --white-16: rgba(255,255,255,.16);
    --white-38: rgba(255,255,255,.38);
    --white-60: rgba(255,255,255,.6);

    --shadow: rgba(0,0,0,.24);
    --shadow-light: rgba(0,0,0,.16);

    --a11y: var(--pink);

    --width: 1140px;

    --bezier: cubic-bezier(.2, .91, .85, .96);

    /* stylelint-disable */
    --body: 'Helvetica Neue', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Arial', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
    --title: 'Plusnet Creighton', 'PlusnetCreighton', 'Helvetica Neue', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
    /* stylelint-enable */
}
