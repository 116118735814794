.u-label {
    display: inline-block;
    border-radius: 16px;
    padding: 0 16px;
    font-size: 12px;
    line-height: 24px;
    white-space: nowrap;

    &--error {
        background-color: var(--error);
    }

    &--warning {
        background-color: var(--warning);
    }

    &--success {
        background-color: var(--success);
    }

    &--info {
        background-color: var(--info);
    }
}
