.u-loading {
    position: relative;
    box-sizing: border-box;

    &::before {
        display: block;
        position: absolute;
        border-style: solid;
        border-color: var(--pink-bright) var(--grey-light) var(--grey-light) var(--grey-light);
        border-radius: 50%;
        content: '';
        animation-name: loadingspinner;
        animation-duration: .8s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        transform-origin: 0 0;
        box-sizing: border-box;
    }

    &:not(.u-loading--overlay)::before {
        transform: rotate(0deg) translate(0, 0);
    }

    &--huge {
        min-width: 72px;
        min-height: 72px;

        &::before {
            top: 36px;
            left: 36px;
            width: 72px;
            height: 72px;
            border-width: 6px;
        }
    }

    &--large {
        min-width: 48px;
        min-height: 48px;

        &::before {
            top: 24px;
            left: 24px;
            width: 48px;
            height: 48px;
            border-width: 4px;
        }
    }

    &--small {
        min-width: 24px;
        min-height: 24px;

        &::before {
            top: 12px;
            left: 12px;
            width: 24px;
            height: 24px;
            border-width: 2px;
        }
    }

    &--overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &::before {
            top: 50%;
            left: 50%;
            transform: rotate(0deg) translate(-50%, -50%);
        }
    }
}

.u-loading__content {
    display: block;

    .u-loading--small & {
        padding-left: 36px;
        line-height: 24px;
    }

    .u-loading--large & {
        padding-left: 60px;
        line-height: 48px;
    }

    .u-loading--huge & {
        padding-left: 82px;
        line-height: 72px;
    }
}

@keyframes loadingspinner {
    from {
        transform: rotate(0deg) translate(-50%, -50%);
    }

    to {
        transform: rotate(360deg) translate(-50%, -50%);
    }
}
