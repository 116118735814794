.u-comp-wrap {
    position: relative;
    padding-top: 128px;
    
    @media (--tablet) {
        padding-top: 32px;
    }

    @media (--desktop) {
        padding-top: 0;
    }
}

.u-comp-wrap::before {
    content: '';
    position: absolute;
    top: -32px;
    height: 200px;
    width: 100%;
    background-image: url('/bundles/plusnetplusnetassets/images/awards/cup-of-tea.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media (--tablet) {
        right: 0;
        width: 327px;
        height: 265px;
        top: -78px;
    }

    @media (--desktop) {
        width: 386px;
        height: 304px;
        top: -92px;
        right: -66px;
    }
}

.u-comp-wrap.u-comp-wrap__mc-banner {
    padding-top: 109px;

    @media (--tablet) {
        padding-top: 60px;
    }

    @media (--desktop) {
        padding-top: 0;
    }
}

.u-comp-wrap.u-comp-wrap__keep-practicing {

    @media (--desktop) {
        padding-top: 55px;
    }
}

.u-comp-wrap.u-comp-wrap__keep-practicing::before {
    z-index: 1;

    @media (--desktop) {
        top: -81px;
    }
}

.u-comp-wrap.u-comp-wrap__mc-banner::before {
    top: -51px;

    @media (--tablet) {
        left: -10px;
        width: 300px;
        height: 250px;
        top: -51px;
    }
    @media (--desktop) {
        width: 282px;
        height: 228px;
        right: -7px;
        top: -67px;
    }
}

.u-comp-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    background-color: #00A1cA;
    text-align: center;
    color: white;

    @media (--tablet) {
        text-align: left;
        flex-direction: row;
    }

    @media (--desktop) {
        text-align: center;
        flex-direction: column;
    }
}

.u-comp-wrap__mc-banner .u-comp-banner {
    @media (--from-tablet) {
        flex-direction: column;
    }
}

.u-comp-banner__body {
    width: 100%;

    @media (--tablet) {
        width: 60%;
    }

    @media (--desktop) {
        width: 50%;
        margin: 0 auto;
    }
}

.u-comp-wrap__mc-banner .u-comp-banner__body {
    @media (--tablet) {
        margin: 0 0 0 auto;
    }
    @media (--desktop) {
        width: 52%;
        margin: 0 auto;
    }
}

.u-comp-banner__lower {
    display: flex;
    align-items: flex-end;
    width: 100%;

    @media (--tablet) {
        width: 40%;
    }

    @media (--desktop) {
        width: 50%;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
}

.u-comp-wrap__mc-banner .u-comp-banner__lower {
    @media (--tablet) {
        width: 60%;
        margin: 0 0 0 auto;
        align-items: center;
    }
    @media (--desktop) {
        width: 50%;
        margin: 0 auto;
    }
}

.u-comp-banner__title {
    position: relative;
    margin-top: 30px;
    margin-bottom: 24px;
    font-family: var(--title);
    font-weight: normal;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    z-index: 0;

    @media (--tablet) {
        margin-top: 0;
        font-size: 30px;
        line-height: 32px;
        text-align: left;
    }

    @media (--desktop) {
        margin-top: 0;
        margin-bottom: 16px;
        text-align: center;
        color: #007390;
        font-size: 40px;
        line-height: 42px;
    }
}

.u-comp-wrap__keep-practicing .u-comp-banner__title {
    @media (--desktop) {
        color: #00A1cA;
    }
}

.u-comp-wrap__mc-banner .u-comp-banner__title {
    color: white;
}

.u-comp-banner__title strong {
    display: block;

    @media (--from-tablet) {
        display: inline-block;
    }
}

.u-comp-banner__title-2nd-line {
    @media (--from-tablet) {
        display: block;
    }
}

.u-comp-banner__content {
    position: relative;
    margin-bottom: 24px;
    padding: 12px;
    border-radius: 8px;
    background-color: #007390;
    font-family: var(--body);
    font-size: 14px;
    line-height: 20px;

    @media (--from-tablet) {
        margin-bottom: 18px;
    }
}

.u-comp-wrap__keep-practicing .u-comp-banner__content {

    @media (min-width: 880px) and (max-width: 913px) {
        margin-bottom: 38px;
    }

    @media (min-width: 914px) and (max-width: 1024px) {
        margin-bottom: 50px;
    }

    @media (--desktop) {
        margin-bottom: 18px;
    }
}

.u-comp-banner__content div:first-child {
    margin-bottom: 14px;
}

.u-comp-banner__footer-text {
    margin-bottom: 24px;
    font-family: var(--title);
    font-weight: normal;
    font-size: 22px;

    @media (--tablet) {
        margin-bottom: 4px;
    }

    @media (--desktop) {
        margin-bottom: 0;
        margin-right: 12px;
    }
}

.u-comp-wrap__mc-banner .u-comp-banner__footer-text {
    margin: 12px 0;
    @media (--from-tablet) {
        margin: 0 0 0 12px;
    }
}

.u-button--primary.u-button--start.u-comp-banner__footer-button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 12px;
    padding: 12px 36px;
    z-index: 0;

    @media (--tablet) {
        margin: 0 45px 4px auto;
    }

    @media (--desktop) {
        margin: 0 0 4px;
    }
}

.u-comp-wrap__mc-banner .u-button--primary.u-button--start.u-comp-banner__footer-button {
    margin: 0;
}

.u-button--primary.u-button--start.u-comp-banner__footer-button::after {
    transform: rotate(45deg) translate(0) !important;
    margin-left: 10px;
}

.u-hidden-tablet {
    display: block;

    @media (--from-tablet) {
        display: none;
    }
}

.u-visible-tablet {
    display: none;

    @media (--from-tablet) {
        display: flex;
    }
}

.u-hidden-desktop {
    display: block;

    @media (--desktop) {
        display: none;
    }
}

.u-visible-desktop {
    display: none;

    @media (--desktop) {
        display: block;
    }   
}
