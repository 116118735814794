.u-product-card-contain {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 12px 0;
    @media (--until-tablet) {
        flex-direction: column;
        align-items: center;
    }
    @media (--from-tablet) {
        align-items: stretch;
        justify-content: flex-start;
    }
}

.u-product-card {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    position: relative;
    height: fit-content;
    border-radius: 16px;
    box-shadow: 1px 1px 4px 1px var(--grey-light);

    &__speed {
        margin-top: 0;
        color: var(--home-blue);
        line-height: 36px;
        font-family: var(--title);
        font-size: 36px;
        font-weight: bold;

        &-caveat {
            line-height: 18px;
            font-size: 18px;
            font-weight: bold;
        }
    }

    &__speed-info-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 16px;

        &__price {
            text-align: right;
        }
    }

    &--callplan {
        padding: 20px;
        @media (--from-tablet) {
            flex: 0 0 263px;
            margin-bottom: 24px;
        }
        @media (--desktop) {
            margin-right: 16px;
        }
        @media (--tablet) {
            &:not(:last-child) {
                margin-right: 12px;
            }
        }
    }

    &--broadband {
        flex-grow: 0;
        @media (--from-tablet) {
            width: 368px;
        }
    }

    &__title {
        margin: 0 0 16px;
        color: var(--black-70);
        line-height: 28px;
        text-align: left;
        white-space: pre-line;
        font-family: var(--title);
        font-size: 32px;
        font-weight: bold;

        &--icon::before {
            display: block;
            width: 44px;
            height: 44px;
            margin: 0 auto 10px;
            content: "";
            background-repeat: no-repeat;
            background-size: contain;
        }

        &--broadband::before {
            background-image: url("./img/icons/icon--broadband.svg");
        }

        &--fibre::before {
            background-image: url("./img/icons/icon--fibre.svg");
        }

        &--evening-and-weekend::before {
            background-image: url("./img/icons/icon--evening-and-weekend.svg");
        }

        &--unlimited-calls::before {
            background-image: url("./img/icons/icon--anytime.svg");
        }

        &--line-only::before {
            background-image: url("./img/icons/icon--line-only.svg");
        }

        &--anytime::before {
            background-image: url("./img/icons/icon--unlimited-calls.svg");
        }
    }

    &__data-disclaimer {
        color: var(--black-70);
        line-height: 20px;
        font-size: 14px;
    }

    &__price {
        color: var(--black-70);
        line-height: 36px;
        font-family: var(--title);
        font-size: 36px;
        font-weight: bold;
    }

    & .u-button {
        width: 100%;
        margin-bottom: 16px;
        padding: 10px 0;
    }

    &__button,
    &__button.u-button--primary.u-button--start {
        width: 100%;
        padding: 10px 0;

        .u-product-card--callplan & {
            margin-bottom: 0;

            .u-product-card-contain & {
                @media (--tablet) {
                    padding: 6px 16px;
                    font-size: 20px;
                }
            }
        }
    }

    & .u-product-card__container-body {
        padding: 0 24px;
    }

    &__header-label {
        position: absolute;
        top: -18px;
        right: 24px;
        padding: 4px;
        color: var(--explore-blue);
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 0 4px rgba(0, 0, 0, .08), 0 2px 4px rgba(0, 0, 0, .12);
        line-height: 20px;
        font-size: 14px;
        font-weight: 700;

        &--fttp {
            color: var(--purple);
        }

        &--personal-offer {
            color: white;
            background: var(--explore-blue);
        }

        &--current-product {
            color: var(--black-70);
        }
        
        &--selected {
            color: var(--black-100);
            background-color: var(--pink-highlighter);
            font-weight: 400;
            font-size: 16px;
        }

        .u-product-card--black-friday &,
        &--black-friday {
            color: white;
            background: black;
        }
    }

    &__container-body {
        margin-top: 16px;

        & .u-expandable__content {
            overflow: unset;
        }
    }

    &__container-header {
        min-height: 24px;
        border-radius: 16px 16px 0 0;

        &--background-pink {
            background: linear-gradient(87.2deg, #c81e6e 1.82%, #87005f 102.81%);
        }

        &--background-blue {
            background: linear-gradient(87.2deg, #007390 1.82%, #87005f 102.81%);
        }
    }

    &__container-footer {
        margin-top: 24px;
        padding: 0 77px;
        text-align: center;
        @media (--tablet) {
            padding: 0 36px;
        }
    }

    &__collapse-content {
        padding-top: 8px;
        padding-left: 18px;
        @media (--from-mobile) {
            padding-top: 18px;
        }
    }

    &--tooltip {
        &-container {
            display: flex;
            top: 1px;
            left: 0;

            & p {
                font-size: 14px;
            }
        }

        &-body {
            padding: 16px;

            &:before {
                top: -7px;
            }

            &.pn-mc-tooltip-content {
                &--is-active {
                    width: 200px;
                    @media (--from-mobile) {
                        width: 270px;
                    }
                }

                &--offset-tablet.pn-mc-tooltip-content--offset-left {
                    top: 46px;
                    transform: translateX(-94%);
                    @media (--from-mobile) {
                        transform: translateX(-94%);
                    }

                    &:before {
                        top: -8px;
                        left: calc(92% - 21px);
                    }
                }
            }
        }

        &-button {
            top: -2px;
            width: 24px;
            height: 24px;
            background-size: 20px;

            &.pn-mc-tooltip-toggle {
                background-image: url("./img/icons/icon--tooltip-default--larger.svg");
                border: 2px solid transparent;

                &--is-active {
                    background-image: url("./img/icons/icon--tooltip-close.svg");
                }
            }

            &:focus {
                border: 2px solid var(--focus-yellow);
            }
        }
    }
    @media (--until-tablet) {
        width: 100%;
        max-width: 380px;
        margin-bottom: 24px;

        &--roundel {
            margin-top: 66px;
        }

        &--callplan {
            max-width: 450px;
        }
    }
}

.product-detail {
    display: flex;
    margin: unset;
    line-height: 20px;
    font-size: 14px;

    & span {
        display: inline-block;
        overflow: hidden;
    }

    &:not(:last-of-type) {
        margin-bottom: 10px;
    }

    &-with-tooltip {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;
    }

    &-icon {
        &:before {
            display: inline-block;
            flex-shrink: 0;
            position: relative;
            top: 4px;
            width: 16px;
            height: 16px;
            box-sizing: border-box;
            margin-right: 8px;
            content: "";
            background-repeat: no-repeat;
            background-size: contain;
        }

        &--mgs:before {
            background-image: url("./img/icons/icon--broadband-speedo--slow-pink.svg");
        }

        &--upload:before {
            background-image: url("./img/icons/icon--upload-pink.svg");
        }

        &--activation:before {
            background-image: url("./img/icons/icon--power-pink.svg");
        }

        &--clock:before {
            background-image: url("./img/icons/icon--clock-pink.svg");
        }

        &--broadband:before {
            background-image: url("./img/icons/icon--broadband--full-pink.svg");
        }

        &--phone:before {
            background-image: url("./img/icons/icon--phone-pink.svg");
        }

        &--router-hub-2:before {
            top: 6px;
            background-image: url("./img/icons/icon--router--hub-2-pink.svg");
        }
    }
}

.u-product-card--selected {
    border: 3px solid var(--pink-highlighter);
}

.u-product-card__banner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0;
    color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    line-height: 18px;
    font-family: var(--title);
    font-size: 16px;

    .u-product-card--current & {
        margin-top: -38px;
        color: #fff;
        background: var(--grey-dark);
        @media (--until-tablet) {
            width: calc(100% + 8px);
        }
        @media (--from-tablet) {
            width: calc(100% + 4px);
        }
    }

    .u-product-card--offer & {
        margin-top: -38px;
        color: #fff;
        background: var(--pink);
        @media (--until-tablet) {
            width: calc(100% + 8px);
        }
        @media (--from-tablet) {
            width: calc(100% + 4px);
        }
    }

    &-label {
        line-height: 24px;
        font-size: 20px;
        font-weight: normal;
    }
}

.u-product-card__roundel {
    position: absolute;
    width: 98px;
    height: 98px;
    color: #fff;
    background-color: var(--purple);
    border-radius: 50%;
    text-align: center;
    font-family: var(--title);

    &-upper-line {
        display: block;
        margin-top: 10px;
        line-height: 24px;
        font-size: 24px;
        font-weight: normal;
    }

    &-middle-line {
        display: block;
        line-height: 34px;
        font-size: 46px;
        font-weight: bold;
        text-transform: uppercase;
    }

    &-lower-line {
        display: block;
        line-height: 18px;
        font-size: 18px;
        font-weight: normal;
        text-transform: uppercase;
    }
    @media (--until-tablet) {
        top: -69px;
        right: 0;
    }
    @media (--tablet) {
        top: -36px;
        right: -4%;
    }
    @media (--desktop) {
        top: -36px;
        right: -4%;
    }
    @media (--desktop-large) {
        top: -36px;
        right: -4%;
    }
}

.u-product-card__link {
    color: var(--pink);
    text-decoration: underline;
    transition: 0.25s color ease;

    &:focus,
    &:hover {
        color: var(--blue-dark);
        text-decoration: none;
    }
}

.u-product-card__collapsible-call-plan {
    margin: 18px 0;
    padding-top: 10px;
    border-top: 1px solid var(--black-38);
}

.u-product-card__call-plan-features {
    margin: 10px 0 !important;
    padding: 0;

    li {
        display: flex;
        margin: 0;
        padding: 0 0 10px 0;
        list-style: none;
        font-size: 14px;

        &::before {
            display: block;
            flex: 0 0 16px;
            width: 16px;
            height: 16px;
            margin-top: 2px;
            margin-right: 7px;
            content: " ";
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}

.u-product-card__call-plan-features--call-plan::before,
.u-product-card__call-plan-features--landline::before {
    background-image: url("./img/icons/icon--phone-pink.svg");
}

.u-product-card__call-plan-features--mobile::before {
    background-image: url("./img/icons/icon--mobile.svg");
}

.u-product-card__call-plan-features--international::before {
    background-image: url("./img/icons/icon--international.svg");
}

.u-product-card__call-plan-features--standard::before {
    background-image: url("./img/icons/icon--tick-maroon.svg");
}

.u-product-card__description {
    flex: 1;
    margin: 10px 14px 0;
    color: var(--grey-dark);
    line-height: 20px;
    font-family: var(--body);
    font-size: 14px;
    font-weight: normal;
}

.u-product-card__list {
    margin-top: 0;
    padding: 0;
    color: var(--grey-dark);
    line-height: 20px;
    font-family: var(--body);
    font-size: 14px;
    font-weight: normal;

    &-decorated {
        text-decoration: underline;
    }
    @media (--desktop) {
        margin: 0 38px 30px;
        padding-left: 8px;
    }
    @media (--tablet) {
        margin: 0 34px 30px;
        padding-left: 12px;
    }
    @media (--until-tablet) {
        margin: 0 20px 32px;
        padding-left: 8px;
    }
}

.u-product-card__list-item {
    @media (--tablet) {
        padding-left: 12px;
    }
}

.u-product-card__pricing {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.u-product-card__price-contract-length {
    margin-bottom: 8px;
    line-height: 24px;
    font-family: var(--title);
    font-size: 20px;
    font-weight: bold;
    @media (--tablet) {
        line-height: 20px;
        font-size: 16px;
    }
}

.u-product-card__price-ex-vat,
.u-product-card__price-info {
    color: var(--grey-dark);
    line-height: 16px;
    font-family: var(--body);
    font-size: 14px;
    font-weight: normal;
    @media (--tablet) {
        line-height: 12px;
        font-size: 10px;
    }
}

.u-product-card__price-ex-vat {
    margin-left: 6px;
}

.u-product-card__price-info {
    display: block;
}

.u-product-card__price-offer-container {
    position: absolute;
    transform: rotate(-5deg) translateY(-92px) translateX(8px);
    background-color: #fff;
}

.u-product-card__price-offer {
    width: 106px;
    color: #fff;
    background-color: var(--purple);
    text-align: center;
    font-family: var(--body);
    font-weight: normal;
    @media (--desktop) {
        margin: 0 4px;
        padding: 2px 8px;
        line-height: 12px;
        font-size: 12px;
        text-transform: uppercase;
    }
    @media (--until-desktop) {
        margin: 0 2px;
        padding: 2px 4px;
        line-height: 10px;
        font-size: 10px;
    }
}

.u-product-card__price-sub-text {
    max-width: 164px;
    margin-bottom: 30px;
    color: var(--grey-dark);
    line-height: 16px;
    text-align: center;
    font-family: var(--body);
    font-size: 12px;
}

.u-product-card__cashback {
    width: 100%;
    max-height: 10%;
    margin-top: 5%;
    margin-bottom: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    background-color: var(--purple);
    text-align: center;
    font-family: var(--body);
    font-weight: normal;
    text-transform: uppercase;
}

.u-product-card__list-header {
    margin-left: -15px;
}

.pn-broadband-filters {
    width: 100%;
    margin: 0 auto;
}

.pn-broadband-filters--filter {
    & h3 {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px;
        font-family: var(--body);
        font-weight: bold;

        @media (--from-tablet) {
            font-size: 16px;
            line-height: 24px;

            margin-bottom: 4px;
        }
    }

    & .select-container {
        width: 100%;
        box-sizing: border-box;
        position: relative;

        @media (--from-mobile) {
            width: 233px;
        }

        &:after {
            display: block;
            content: '';
            position: absolute;
            background-image: url('./img/icons/icon--chevron-down--grey.svg');
            background-size: contain;
            height: 6px;
            width: 10px;
            font-size: 12px;
            pointer-events: none;
            right: 20px;
            top: 22px;
        }
    }


    & select {
        width: 100%;
        padding: 12px;
        font-size: 16px;
        line-height: 24px;

        border-radius: 4px;
        border: 0;

        box-shadow: inset 0px 0px 0px 2px var(--black-38);
        box-sizing: border-box;

        appearance: none;
        &::-ms-expand {
            display: none;
        }
    }
}

.u-product-card__countdown {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 8px 24px;
    color: #fff;
    line-height: 20px;
    font-size: 14px;
    font-weight: bold;
    @media (--desktop) {
        flex-wrap: nowrap;
        padding: 24px 24px 16px;
        line-height: 16px;
    }

    &--time-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &--time-value {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 24px;
        margin-right: 8px;
        color: var(--black-70);
        background: #fff;
        border-radius: 8px;
        @media (--desktop) {
            margin-right: 6px;
        }
    }

    &--prefix-text {
        flex-basis: 100%;
        margin-bottom: 6px;
        @media (--desktop) {
            flex-basis: unset;
            margin-bottom: 0;
        }
    }
}

.u-product-card___reward-section {
    border-radius: 4px;
    box-sizing: border-box;
    height: 40px;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 40px;
    background-color: var(--grey-lightest);

    &::before {
        content: '';
        position: relative;
        top: 6px;
        display: inline-block;
        width: 38px;
        height: 24px;
        margin-right: 8px;
        margin-left: 8px;
        background-image: url('./img/icons/icon--reward-card.svg');
        background-repeat: no-repeat;
        background-size: contain;
    }

    & .u-product-card--tooltip-container {
        top: -3px;
    }
}
